<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <span>Paramètres > Rubrique</span>
      </div>
    </div>
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Liste des rubriques
          </div>
        </div>
      </div>
    </div>
    <div class="inner_container">
      <div class="table_action">
        <div class="div_left">
          <button class="btn btn-blue"
                  data-toggle="modal" 
                  data-target="#exampleModal"
                  @click="btn_stat='rubrique'">
            Ajouter une rubrique
          </button>
        </div>
        <div class="div_right"></div>
      </div>
      <div class="row">
        <Notif :notif="notif" />
      </div>
      <div class="tab_holder">
        <div
          class="col_table"
          v-if="rubriques.donnees"
        >
          <div class="col_header"
               style="font-size:0.7em ;">
            <div class="row">
              <div class="col-3">Libellé</div>
            </div>
          </div>
          <div
            class="row no-mr"
            v-for="(rubrique,rubkey) in rubriques.donnees"
            :key="rubkey"
          >
            <div  class="col-md-12">
              <div class="row light_row">
                <div class="col-md-8 ">
                  <label style="font-size:0.7em ;">{{rubrique.libelle}}</label>
                </div>
                <div class="col-md-4 text-right">
                  <button class="btn btn-blue mr-1"
                          @click="btn_stat='activite',id_rubrique=rubrique.id"
                          data-toggle="modal" 
                          data-target="#exampleModal">+</button>
                  <button type="button"
                          @click="btn_stat='mod_rubrique',id_rubrique=rubrique"
                          data-toggle="modal" 
                          data-target="#exampleModal" 
                          class="btn btn-blue mr-1">
                    <i class="flaticon-pencil"></i>
                  </button>
                  <button type="button"
                          @click="btn_stat='del_rubrique',id_rubrique=rubrique.id"
                          data-toggle="modal" 
                          data-target="#exampleModal" 
                          class="btn btn-blue">
                    <i class="flaticon-delete"></i>
                  </button>
                </div>
              </div>
              <div
                class="row alter"
                v-for="(activite,actkey) in rubrique.activites"
                :key="actkey"
              >
                <div class="col-md-10 "
                     style="font-size:0.7em ;">{{activite.libelle}}</div>
                <div class="col-md-2 text-right tb_row">
                  <button type="button"
                          @click="btn_stat='mod_activite',id_act=activite"
                          data-toggle="modal" 
                          data-target="#exampleModal" 
                          class="btn btn-blue mr-1">
                    <i class="flaticon-pencil"></i>
                  </button>
                  <button type="button"
                          @click="btn_stat='del_activite',id_act=activite.id"
                          data-toggle="modal" 
                          data-target="#exampleModal"
                          class="btn btn-blue">
                    <i class="flaticon-delete"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" 
         id="exampleModal" 
         tabindex="-1" 
         role="dialog" 
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog" 
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="btn_stat=='rubrique'"
                class="modal-title" 
                id="exampleModalLabel">Ajouter Rubrique</h5>
            <h5 v-else-if="btn_stat=='del_rubrique'"
                class="modal-title" 
                id="exampleModalLabel">Supprimer Rubrique</h5>
            <h5 v-else-if="btn_stat=='mod_rubrique'"
                class="modal-title" 
                id="exampleModalLabel">Modifier Rubrique</h5>
            <h5 v-else-if="btn_stat=='del_activite'"
                class="modal-title" 
                id="exampleModalLabel">Supprimer Activité</h5>
            <h5 v-else-if="btn_stat=='mod_activite'"
                class="modal-title" 
                id="exampleModalLabel">Modifier Activité</h5>        
            <h5 v-else
                class="modal-title" 
                id="exampleModalLabel">Ajouter Activité</h5>
            <button type="button" 
                    class="close"
                    ref="modal_button" 
                    data-dismiss="modal" 
                    aria-label="Close"
                    @click="destroyComponent()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body"
               v-if="btn_stat=='rubrique'">
            <CreateRubriques @rubrique="type => (id = type)"/>
          </div>
          <div class="modal-body"
               v-else-if="btn_stat=='mod_rubrique'">
            <UpdateRubriques :id_mod_rubrique="id_rubrique"
                             @uprubrique="msg => (instruction = msg)"/>
          </div>
          <div class="modal-body"
               v-else-if="btn_stat=='mod_activite'">
            <UpdateActivites :id_activite="id_act"
                             @upactivite="msg => (instruction = msg)"/>
          </div>
          <div class="modal-body"
               v-else-if="btn_stat=='del_rubrique'">
            <p class="text-center"> La suppression de cette rubrique entrainera la suppression de tous les éléments qui en découlent (activités, marchés, decomptes etc...).</p>
            <p class="text-center">Voulez-vous vraiment effectuer cette action?</p>
            <p class="text-center">
              <button class="btn btn-blue mr-2"
                      @click="delRubrique()">Oui</button>
              <button class="btn btn-blue">Non</button>
            </p>
          </div>
          <div class="modal-body"
               v-else-if="btn_stat=='del_activite'">
            <p class="text-center"> La suppression de cette activité entrainera la suppression de tous les éléments qui en découlent (marchés, decomptes etc...).</p>
            <p class="text-center">Voulez-vous vraiment effectuer cette action?</p>
            <p class="text-center">
              <button class="btn btn-blue mr-2"
                      @click="delActivite()">Oui</button>
              <button class="btn btn-blue">Non</button>
            </p>
          </div>
          <div class="modal-body"
               v-else>
            <CreateActivites :id_rubrique="id_rubrique"
                             :id_rub="id.id"
                             :type="id.type"
                             v-if="btn_stat!=''"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
  @import './rubriques.css';
  /* form input[type="text"], 
form input[type="date"], 
form input[type="number"], 
form input[type="file"], 
form textarea,
form select option,
form select.form-control,
span[slot="no-options"].text-danger{
  font-size: 0.6em;
} */
form select.form-control option{
  font-size: 1em;
}
input.vs__search{
  font-size: 0.7em;
}
form.form_default input[type="text"], 
form.form_default input[type="date"], 
form.form_default input[type="number"], 
form.form_default input[type="file"], 
form.form_default textarea{
  height: fit-content;
}
/* div.vs__dropdown-toggle,form select.form-control{
  height: 3.5vh;
} */

@media only screen and (width > 1466px){
  div.vs__dropdown-toggle,form select.form-control{
  height: 2.5vh;
  }
}
/* form.form-inline input,
form.form-inline select, */
li.nav-item
/* form.form-inline button */{
  font-size: 0.6em;
}
</style>
<script>
import CreateRubriques from "./AjoutRubriques"
import CreateActivites from "./AjoutActivite"
import UpdateRubriques from "./ModifierRubriques"
import Notif from "@/components/shared/Toast"
import UpdateActivites from "./ModifierActivite"


import {mapActions,mapMutations, mapGetters} from "vuex"
export default {
  name: "Rubriques",
  components:{
    CreateRubriques,
    CreateActivites,
    UpdateRubriques,
    UpdateActivites,
    Notif
  },
  data: () => ({
    btn_stat:"",
    id_rubrique:"",
    id_act:"",
    instruction:"",
    // listOfAudio:[
    //   song1,
    //   song2,
    //   song3,
    //   song4
    // ],
    // counter:1,
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    id:"",
    rubriquesData:{
      'libelle':"ACTIVITES D'EXPLOITATION"
    }
  }),
  watch:{
    id(){
      if (this.id) {
        this.btn_stat="activite"
        this.id_rubrique = ""
        this.id_rubrique = this.id
      }
    },
    instruction(){
      if (this.instruction) {
        this.setRubriques("")
        this.Rubriques()
        this.$refs["modal_button"].click()
      }
    },
    singleRubriques(){
      if (this.singleRubriques) {
        this.notif.message = this.singleRubriques.msg
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.singleRubrique("")
          }.bind(this),
          3000
        )
      }
    },
    singleActivites(){
      if (this.singleActivites) {
        this.notif.message = this.singleActivites
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.singleActivite("")
            this.setRubriques("")
            this.Rubriques()
            this.$refs["modal_button"].click()
          }.bind(this),
          3000
        )
      }
    },
    delRubriques(){
      if (this.delRubriques) {
        this.notif.message = this.delRubriques.msg
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.supprimerRubrique("")
            this.setRubriques("")
            this.Rubriques()
            this.$refs["modal_button"].click()
          }.bind(this),
          3000
        )
      }
    },
    delActivites(){
      if (this.delActivites) {
        this.notif.message = this.delActivites.msg
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.supprimerActivite("")
            this.setRubriques("")
            this.Rubriques()
            this.$refs["modal_button"].click()
          }.bind(this),
          3000
        )
      }
    },
    
  },

  created(){
    this.Rubriques()
  },
  computed: {
    ...mapGetters(['rubriques','errors','singleRubriques','singleActivites','delRubriques','delActivites',])
  },
  methods: {
    ...mapActions(["Rubriques","deleteRubriques","deleteActivites"]),
    ...mapMutations(['singleRubrique','singleActivite',"setRubriques",'supprimerRubrique','supprimerActivite',]),
    delActivite(){
      this.deleteActivites({id:this.id_act})
    },
    delRubrique(){
      this.deleteRubriques({id:this.id_rubrique})
    },
    destroyComponent(){
      this.btn_stat=""
    }
  }
}
</script>
