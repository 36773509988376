<template>
  <div>
    <form class="form_default">
      <div class="form-group">
        <label  class="req">Libellé</label>
        <input
          type="text"
          name="libelle"
          v-model='form.libelle'
          class="form-control form-control-lg"
          aria-label=".form-control-lg example"
        >
        <span
          v-if="form.errors().has('libelle')"
          v-text="form.errors().get('libelle')"
          class="errorMsg">
        </span>
      </div>
      <div class="button_form">
        <button
          type="button"
          class="btn btn-f-blue enter_btn"
          @click='submit'>
          <i class="flaticon-floppy-disk"></i> Enregistrer
        </button>
        <button
          type="reset"
          class="btn btn-f-blue cancle_btn ml-4">
          <i class="flaticon-cancel"></i> Annuler
        </button>
      </div>
    </form>
  </div>
</template>
<style>
  @import './rubriques.css';
</style>
<script>
import form from 'vuejs-form'
import {mapActions,mapGetters} from "vuex"
export default {
  name: "CreateActivites",
  props:["id_rubrique","id_rub","type"],
  data: () => ({
    form: form({
      libelle: '',
      id: ''
    })
      .rules({
        libelle: 'required',
        id: 'required'
      })
      .messages({
        'libelle.libelle': 'Champ requis!',
        'id.id': 'Champ requis!'
      })
  }),
  watch: {
    ['form.data']: {
      deep: true,
      immediate: false,
      handler: 'onFormChange'
    },
    
  },
  computed: {
    ...mapGetters(['acterrors'])
  },
  methods: {
    ...mapActions(["saveActivites"]),
    onFormChange() {
      this.form.validate()
    },
    submit() {
      if (this.type=="fromrub") {
        this.form.id=this.id_rub
      }else{
        this.form.id=this.id_rubrique
      }
      if (!this.form.validate().errors().any()) {
        this.saveActivites(this.form.data)
      }
    },
    destroyComponent(){
      this.$parent.$refs["modal_button"].click()
    }
  }
}
</script>
